.App {
  text-align: center;
  font-family:calibri;
}

.hidden {
    opacity: 0;
    transition: all 1s;

}
.show {
    opacity: 1;
}